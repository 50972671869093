@font-face {
  font-family: Myriad-Bold;
  src: url(./fonts/Myriad/MYRIADPRO-BOLD.OTF);
}
@font-face {
  font-family: Myriad-Regular;
  src: url(./fonts/Myriad/MYRIADPRO-REGULAR.OTF);
}
@font-face {
  font-family: Myriad-Light;
  src: url(./fonts/Myriad/MyriadPro-Light.otf);
}

html, body {
  background-color: #0F1836;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}
a {
  text-decoration: none !important;
  color: inherit !important;
}
::placeholder {
  color: #fff !important;
  opacity: 1; /* Firefox */
  font-size: 15px;
}

::-ms-input-placeholder { /* Edge 12 -18 */
  color: #fff !important;
  font-size: 15px;
}

.form-control {
  border: 1px solid #fff;
  background-color: transparent !important;
  color: #fff !important;
  border-radius: 20px !important;
  font-size: 15px !important;
}

.hidden {
  display: none !important;
}
.img-100 {
  width: 100%;
}
.logo {
  width: 150px;
}
.player-v {
  width: 100% !important;
  height: calc( 100vw / 2.158 ) !important;
}
.section {
  padding-top: 100px;
  background-color: #0F1836;
  background-image: url(./img/section_back.png);
  background-position: right;
  background-size: cover;
}
.section-simple {
  padding-top: 100px;
  background-color: #0F1836;
}
.home-desc {
  font-family: 'Myriad-Regular', sans-serif;
  font-size: 18px;
  color: #fff;
  text-align: center;
}
.us-item-container {
  background-image: url(./img/box.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 30px;
}
.us-item-title {
  color: #03FFFF;
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  font-weight: 700;
  text-align: center;
}
.us-item-desc {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
}
.section-grl {
  padding: 10px 0 50px 0;
}
.no-padding {
  position: relative;
  padding: 0 !important;
}
.service-button {
  position: relative !important;
  border-radius: 20px;
  color: #0069B5 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 20px !important;
  padding-right: 10px !important;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  height: 65px;
  cursor: pointer;
  z-index: 10;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 85%;
}
.arrow-small {
  width: 14px;
}
.acc-a {
  background-color: #fff;
}
.acc-b {
  background-color: #9FDAFF;
}
.services-items {
  position: relative;
}
.background-comp {
  position: absolute;
  top: 0;
  right: -20%;
  width: 50%;
  height: 100%;
  z-index: -1;
}
.card-shadow {
  box-shadow: -16px 0px 31px -13px rgba(0,0,0,0.5);
}
.footer {
  padding-top: 50px;
  background-color: #0F1836;
}
.footer-title {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 45px;
  font-weight: 800;
  color: #fff;
  line-height: 50px;

}
.footer-desc {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #04C9FF;
  line-height: 30px;
}
.form-button {
  border: 1px solid #0069B5 !important;
  background-color: #9fdaff !important;
  color: #0069B5 !important;
  padding: 15px 30px !important;
  border-radius: 20px !important;
  font-size: 15px !important;
  font-weight: 800;
}
.footer-icon {
  color: #fff;
  font-size: 18px;
}
.footer-contact {
  position: relative;
  top: 2px;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
}
.footer-logo {
  max-width: 150px;
}
.footer-policy {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.footer-rrss-icons{
  font-size: 24px;
  color: #04C9FF;
}
.hr-line {
  border: 1px solid #fff;
  opacity: 1;
}
.nav-item-text {
  font-family: 'Poppins', sans-serif;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 5px 20px !important;
}
.nav-item-text-separator {
  border-left: 1px solid #000 !important;
}

.nav-item-text-botton {
  background-color: #2584C7 !important;
  border-radius: 20px;
  color: #fff !important;
}

.service-header {
  width: 100%;
  max-height: 500px;
}
.service-background {
  background-position: center;
}
.service-title {
  height: 80px;
}
.service-img-70 {
  width: 70%;
}
.service-img-100 {
  width: 100%;
}
.bullet-service {
  width: 80%;
  padding-bottom: 10px;
}
.bullet-text {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  padding-bottom: 10px;
}
.btn-back {
  position: absolute;
  top: 100px;
  left: 30px;
  cursor: pointer;
}
.back-img {
  width: 35px;
}
.parallax-item-height {
  height: 500px
}
.parallax-conteiner {
  position: 'relative';
}
.parallax-conteiner-mobile {
  display: none;
}
.carousel-item-container {
  position: relative;
}
.carousel-shadow {
  background-image: url(./img/services/shadow.png);
  padding: 30px 0;
}
.carousel-title-1 {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
.carousel-title-2 {
  margin: 0;
  position: absolute;
  top: 10%;
  right: 15%;
}
.carousel-title-3 {
  margin: 0;
  position: absolute;
  bottom: 10%;
  left: 15%;
}
.carousel-title-4 {
  margin: 0;
  position: absolute;
  bottom: 10%;
  left: 15%;
}
.carousel-title-5 {
  margin: 0;
  position: absolute;
  bottom: 10%;
  right: 15%;
}
.carousel-title-6 {
  margin: 0;
  position: absolute;
  bottom: 10%;
  right: 15%;
}
.carousel-title-7 {
  margin: 0;
  position: absolute;
  top: 10%;
  left: 15%;
}
.carousel-text {
  font-family: 'Poppins', sans-serif;
  font-size: 65px;
  font-weight: 700;
  line-height: 70px;
  color: #9AF7FF;
}
.carousel-text-color {
  color: #fff;
  font-size: 60px;
  line-height: 65px;
}
.carousel-botton {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  background-color: #04C9FF !important;
  border-radius: 20px;
  padding: 10px 20px;
  color: #fff !important;
  width: fit-content;
}
.carousel-width {
  max-width: 500px;
}
.carousel-display-web{
  display: block;
}
.carousel-display-mobile{
  display: none;
}

/* Estilo para el botón flotante de WhatsApp */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 1000;
  /* Aplicamos la animación de crecer y encogerse */
  animation: growShrink 1.5s infinite ease-in-out;
}

.whatsapp_icon {
  width: 100%;
  height: 100%;
}

/* Definimos la animación con keyframes */
@keyframes growShrink {
  0% {
    transform: scale(1);  /* Tamaño original */
  }
  50% {
    transform: scale(1.1);  /* Crece un 20% */
  }
  100% {
    transform: scale(1);  /* Vuelve al tamaño original */
  }
}

@media (max-width: 1199px) {
  /*.services-items {
      flex: 0 0 auto !important;
      width: 33.33333333333% !important;
  }*/
  .services-space {
    display: none;
  }
  .btn-back {
    top: 90px;
  }
  .us-item-container {
    padding: 25% 10%
  }
  .service-title {
    height: 60px;
  }
  .bullet-service {
    width: 70%;
  }
  .carousel-text-color {
    font-size: 45px;
    line-height: 50px;
  }
  .carousel-width {
    max-width: 400px;
  }
}

@media (max-width: 1000px) {
  .tablet-class {
    display: none;
  }
}

@media (max-width: 767px) {
  .background-comp {
    display: none;
  }
}

@media (max-width: 460px) {
  .background-comp {
    display: none;
  }
  .services-items {
    flex: 0 0 auto !important;
    width: 100% !important;
  }
  .us-item-container {
    width: 80%;
    margin-bottom: 15px;
  }
  .truck-mobile, .services-space {
    display: none;
  }
  .nav-item-text-separator {
    border-left: none !important;
  }
  .nav-item-text {
    text-align: center;
  }
  .section {
    padding-top: 50px;
    /*background-image: url();*/
  }
  .footer-logo-style {
    text-align: center;
    padding-bottom: 20px;
  }
  .footer-policy {
    text-align: center;
    font-size: 12px;
  }
  .section-simple {
    padding-top: 50px;
  }
  .service-title {
    height: 50px
  }
  .parallax-item-height {
    height: 250px
  }
  .bullet-service {
    width: 80px;
  }
  .banner-mobile {
    display: none;
  }
  .back-img {
    width: 25px;
  }
  .parallax-conteiner {
    display: none;
  }
  .parallax-conteiner-mobile {
    display: block;
  }
  .vertical-center {
    width: 95%;
  }
  .carousel-text-color {
    font-size: 50px;
    line-height: 55px;
  }
  .carousel-width {
    width: 100%;
    max-width: 100%;
    background-image: url(./img/services/shadow.png);
    padding: 30px 0;
  }
  .carousel-display-web{
    display: none;
  }
  .carousel-display-mobile{
    display: block;
  }
  .carousel-text {
    font-size: 45px;
    line-height: 50px;
  }
  .carousel-title-2, .carousel-title-3, .carousel-title-4, .carousel-title-5, .carousel-title-6, .carousel-title-7 {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
  }
}